<template>
<!--    <div class="login-slider">-->
<!--        <img alt="Kolay Panel Logo" src="../assets/slide.svg" width="100%;">-->
<!--    </div>-->
</template>

<script>
    export default {
        name: 'LoginProductsComponent',
        data() {
            return {}
        }
    }
</script>

<style scoped lang="scss">
    .login-slider {
        width: 100%;
        text-align: center;
        padding-top:20px;
    }
</style>
